import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  BackgroundImages,
  FishermanHeader,
  MarkdownContent,
  OutboundLink,
  FadeReveal,
  BasicCarousel,
  componentIterator,
  Image,
  InternalLink,
  FilteredList,
  MultipleComponentIterator,
  MultipleComponentIteratorMap,
  HorizontalImageCard,
  PriceRange,
  FishermanIcon,
  ScrollableContent,
  FullImageCard,
  TeamMemberV2,
} from "@bluefin/components";
import { Button, Grid, Label, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessFile,
      allFishermanBusinessMenuItem,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <Section className={"default-hero-section"}>
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              carouselProps={{
                leftArrowIcon: "arrow-left",
                rightArrowIcon: "arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <div className={"content-container"}>
                <FishermanHeader
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "services_hero_header",
                    defaultValue: "Services",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "services_hero_description",
                  })}
                />
                <div className={"ctas-container"}>
                  <Button
                    primary={true}
                    as={OutboundLink}
                    to={bookingUrl.url}
                    event={{
                      category: "Booking",
                      action: "Schedule Appointment Intent",
                    }}
                    content={bookingUrl.title}
                  />
                </div>
              </div>
            </BackgroundImages>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <BasicCarousel
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <div>
                    <FishermanHeader
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_header",
                        defaultValue: "About Us",
                      })}
                    />
                    <FishermanHeader
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_subheader",
                        defaultValue: "Learn About What Makes Us Different",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      to={"/about/"}
                      as={InternalLink}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_more_about_button",
                        defaultValue: "Get to Know Us",
                      })}
                      event={{ category: "About", action: "View About" }}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"digital-services-section"}>
            <FilteredList
              items={allFishermanBusinessMenuCategory.nodes}
              itemFilterKey={"schedule.name"}
              childItemsKey={"iterator"}
              defaultFilterValue={"All Services"}
              displayAllFilterValue={"All Services"}
              filterValues={allFishermanBusinessMenuSchedule.nodes}
              filterValueKey={"name"}
              filterValuesComponent={<Button basic={true} primary={true} />}
              className={"services-filtered-list"}
            >
              <MultipleComponentIterator
                components={[
                  {
                    component: <div className={"service-container"} />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <Grid className={"category-info"} columns={2} />
                        ),
                        children: [
                          {
                            component: (
                              <Grid.Column
                                className={"info-column"}
                                width={8}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <FishermanHeader
                                    className={"name"}
                                    as={"h2"}
                                  />
                                ),
                                propMap: { content: "name" },
                              },
                              {
                                component: (
                                  <MarkdownContent className={"description"} />
                                ),
                                propMap: { content: "description" },
                              },
                              {
                                component: (
                                  <Button
                                    primary={true}
                                    content={getComponentContentNodeContent({
                                      components:
                                        fishermanBusinessWebsitePage.components,
                                      componentId:
                                        "see_service_category_details_button",
                                      defaultValue: "See Details",
                                      enforceDefault: true,
                                    })}
                                    event={{
                                      category: "Services",
                                      action: "View Category Details",
                                    }}
                                    as={InternalLink}
                                  />
                                ),
                                propMap: {
                                  "event.label": "name",
                                  to: createServiceCategorySlug,
                                },
                              },
                            ],
                          },
                          {
                            component: (
                              <Grid.Column
                                className={"image-column"}
                                width={8}
                              />
                            ),
                            children: [
                              {
                                component: <Image />,
                                propMap: {
                                  src: withNamedArgs({
                                    func: getBusinessFiles,
                                    args: {
                                      businessFiles:
                                        allFishermanBusinessFile.nodes,
                                      numToSelect: 1,
                                    },
                                  }),
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: (
                          <Card.Group
                            className={"category-items"}
                            itemsPerRow={2}
                          />
                        ),
                        children: [
                          {
                            component: (
                              <MultipleComponentIteratorMap
                                lookupField={"_id"}
                                limit={null}
                                iterator={allFishermanBusinessMenuItem.nodes}
                                components={[
                                  {
                                    component: (
                                      <FadeReveal
                                        className={"ui card"}
                                        triggerOnce={true}
                                      />
                                    ),
                                    propMap: { key: "_id" },
                                    children: [
                                      {
                                        component: (
                                          <HorizontalImageCard
                                            as={InternalLink}
                                            link={true}
                                            event={{
                                              category: "Services",
                                              action: "View Item Details",
                                            }}
                                            imagePosition={"right"}
                                          />
                                        ),
                                        propMap: {
                                          image: withNamedArgs({
                                            func: getBusinessFiles,
                                            args: {
                                              businessFiles:
                                                allFishermanBusinessFile.nodes,
                                              numToSelect: 1,
                                            },
                                          }),
                                          "event.label": "name",
                                          to: withArgs({
                                            func: createServiceItemSlug,
                                            args: [
                                              allFishermanBusinessMenuCategory.nodes,
                                            ],
                                          }),
                                        },
                                        children: [
                                          {
                                            component: (
                                              <Card.Header
                                                className={"header-container"}
                                              />
                                            ),
                                            children: [
                                              {
                                                component: <Card.Header />,
                                                propMap: { content: "name" },
                                              },
                                              {
                                                component: (
                                                  <PriceRange
                                                    priceKey={"price"}
                                                    settings={{
                                                      displayType: "range",
                                                      displayZeroCost: false,
                                                    }}
                                                  />
                                                ),
                                                propMap: {
                                                  prices: "variations",
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            component: <MarkdownContent />,
                                            propMap: { content: "description" },
                                          },
                                          {
                                            component: (
                                              <div
                                                className={"button-container"}
                                              />
                                            ),
                                            children: [
                                              {
                                                component: (
                                                  <Label
                                                    content={getComponentContentNodeContent(
                                                      {
                                                        components:
                                                          fishermanBusinessWebsitePage.components,
                                                        componentId:
                                                          "see_service_category_details_button",
                                                        defaultValue:
                                                          "See Details",
                                                        enforceDefault: true,
                                                      }
                                                    )}
                                                  />
                                                ),
                                              },
                                              {
                                                component: (
                                                  <FishermanIcon
                                                    iconName={"arrow-right"}
                                                  />
                                                ),
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ]}
                              />
                            ),
                            propMap: { targetValues: "items" },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </FilteredList>
          </Section>
          <Section className={"team-section"}>
            <ScrollableContent
              direction={"horizontal"}
              distanceFactor={"snap"}
              intervalFactor={500}
            >
              <Grid stackable={true} columns={2}>
                <Grid.Column className={"content-column"} width={4}>
                  <FishermanHeader
                    as={"h2"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_header",
                      defaultValue: "Our Team",
                    })}
                  />
                  <FishermanHeader
                    as={"h3"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_subheader",
                      defaultValue: "Boost Your True Beauty",
                    })}
                  />
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_description",
                    })}
                  />
                  <Button
                    as={InternalLink}
                    to={"/team/"}
                    primary={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "see_all_team_button",
                      defaultValue: "See Our Team",
                    })}
                    className={"see-more-button"}
                    event={{
                      category: "Team",
                      action: "View All Team Members",
                    }}
                  />
                  <div className={"triggers-container"}>
                    <ScrollableContent.PreviousTrigger />
                    <ScrollableContent.NextTrigger />
                  </div>
                </Grid.Column>
                <Grid.Column className={"team-member-cards-column"} width={12}>
                  <ScrollableContent.Content hideScrollbar={true}>
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessTeam.nodes}
                      components={[
                        {
                          component: (
                            <FadeReveal
                              className={"ui card"}
                              triggerOnce={true}
                            />
                          ),
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: (
                                <FullImageCard
                                  link={true}
                                  className={"team-member-card"}
                                  as={InternalLink}
                                  event={{
                                    category: "Team",
                                    action: "View Team Member",
                                  }}
                                />
                              ),
                              propMap: {
                                key: "_id",
                                image: "gatsbyImage",
                                to: createTeamMemberSlug,
                              },
                              children: [
                                {
                                  component: <TeamMemberV2.Name />,
                                  propMap: { name: "name" },
                                },
                                {
                                  component: <TeamMemberV2.Role />,
                                  propMap: { role: "role" },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </ScrollableContent.Content>
                  <div className={"actions-container"}>
                    <Button
                      as={InternalLink}
                      to={"/team/"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_all_team_button",
                        defaultValue: "See Our Team",
                      })}
                      className={"see-more-button"}
                      primary={true}
                    />
                    <div className={"triggers-container"}>
                      <ScrollableContent.PreviousTrigger />
                      <ScrollableContent.NextTrigger />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </ScrollableContent>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
        scheduleId
        schedule {
          _id
          name
        }
        files
      }
    }
    allFishermanBusinessMenuSchedule(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        order
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
